<template>
  <div class="shop-goods-info">
    <div class="goods-img" @click="handleClick">
      <img :src="goodsInfo.imgUrl" alt="位元灵感"/>
    </div>
    <div class="goods-title">
      <div class="title">{{ goodsInfo.title }}</div>
      <!--      <div class="sub-desc">{{ goodsInfo.desc }}</div>-->
    </div>
    <div class="goods-count dp_f">
      <div class="price">￥{{ $filters.toYuan(goodsInfo.price, '商城') }}/{{ goodsInfo.unit }}</div>
      <count-number v-model="number"></count-number>
    </div>
    <!--点击商品弹窗-->
    <div class="pop-goods-info  animate__animated animate__fadeIn" :class="goodsInfo.goodsId === 2?'decal':'pda'"
         v-show="showInfo">
      <div class="close-btn cur_p" @click="handleClose">
        <el-icon>
          <Close/>
        </el-icon>
      </div>
      <img :src="goodsInfo.imgUrl" alt="位元灵感" class="pop-goods-img"/>
      <div class="title">{{ goodsInfo.title }}</div>
      <div v-html="goodsInfo.desc"></div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref, toRefs, watch} from "vue";
import CountNumber from "@views/shop/components/count.vue";

const showInfo = ref(false)
const {proxy} = getCurrentInstance();
const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => {
    },
  },
})
const {goodsInfo} = toRefs(props)
const emit = defineEmits(['changeCount'])
const number = ref(goodsInfo.value.count || 0)
const handleClick = () => {
  showInfo.value = true
}
const handleClose = () => {
  showInfo.value = false
}
watch(() => number.value, (val) => {
  emit('changeCount', val)
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopGoodsInfo",
});
</script>

<style lang="scss" scoped src="./index.scss"></style>
<style lang="scss" scoped>
.shop-goods-info {
  width: 500px;
  position: relative;
  //hover上去有立体的效果
  &:hover {
    .goods-img {
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
      transition: 0.1s all linear;
    }
  }
  .goods-img {
    width: 500px;
    height: 500px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #F5F6F7;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .goods-title {
    margin-top: 24px;
    color: $shopFsColor;
    line-height: 1;
    @include textOverflow(100%);

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .sub-desc {
      font-size: 16px;
      overflow: hidden;
    }

    > div {
      display: inline-block;
    }
  }

  .goods-count {
    margin-top: 16px;

    .price {
      font-size: 18px;
    }
  }
}
</style>
