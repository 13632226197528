<template>
  <div class="">
    <march-title title="购买信息">
    </march-title>
    <div class="buy-info-content container mt_60">
      <form-item title="企业名称" :value="companyName"></form-item>
      <template v-if="formData.addressId">
        <form-item title="联系人" class="mt_32" :value="formData.name"></form-item>
        <form-item title="联系电话" class="mt_32" :value="formData.mobile"></form-item>
        <form-item title="收货地址" class="mt_32" :value="formData.address"></form-item>
      </template>
      <form-item title="收货信息" class="mt_32" required>
        <div class="form-item">
          <el-select
              placeholder="请选择收货人信息"
              class="shop-input w480"
              v-model="formData.addressId"
              @change="changAddress"
              style="font-size: 18px;"
          >
            <el-option
                v-for="item in tableData"
                :key="item.id"
                :label="item.str"
                :value="item.id"
            />
          </el-select>
          <span class="add" @click="handleAdd">新增</span>
        </div>
      </form-item>
    </div>
    <add-address-dia ref="addDia" @update="getAddressList"></add-address-dia>
  </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref, watch} from "vue";
import MarchTitle from '../match/title.vue'
import FormItem from "@views/shop/components/formItem.vue";
import {getUserInfo} from "@utils/tool/getUserInfo.js";
import {addressList} from '@utils/api/address.js';
import AddAddressDia from "@views/shop/personal/personal/components/addAddressDia.vue";

const addDia = ref(null)
const tableData = ref([])
const getAddressList = async () => {
  const res = await addressList()
  if (res.code !== 0) {
    proxy.$message.error(res.msg)
    return
  }
  res.data.list.forEach(item => {
    item.str = `${item.address} ${item.name} ${item.mobile}`
  })
  tableData.value = res.data.list || []
}
onMounted(() => {
  getAddressList()
})
const {companyName} = getUserInfo()
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
})
const emit = defineEmits(['update:modelValue', 'change'])
const value = ref('');
const {proxy} = getCurrentInstance();
const handleAdd = () => {
  if (tableData.value.length >= 10) {
    proxy.$message.error('最多只能添加10个收货地址')
    return
  }
  addDia.value.dialogVisible = true
}
const formData = ref({
  //收货地址
  address: '',
  //联系人
  name: '',
  //联系电话
  mobile: '',
  addressId: '',
})
const changAddress = () => {
  let {address, name, mobile} = tableData.value.find(item => item.id === formData.value.addressId)
  formData.value.name = name + ''
  formData.value.mobile = mobile + ''
  formData.value.address = address + ''
}

defineExpose({
  formData,
})
watch([() => formData.value], () => {
  emit('changeObj')
}, {deep: true})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "buyInfo",
});
</script>

<style lang="scss" scoped>
.buy-info-content {
  padding: 0 70px 130px;
}

.form-item {
  span {
    font-size: 18px;
    color: $mainColor;
    margin-left: 16px;
    cursor: pointer;
  }
  .shop-input{
  :deep(.el-input__inner){
      font-size: 18px;
    }
  }
}
</style>
