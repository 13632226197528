<template>
  <div class="page-title container">
    <h1 v-if="title">{{ title }}</h1>
    <h2 v-if="subTitle">{{ subTitle }}</h2>
  </div>
</template>

<script setup>
import {getCurrentInstance, toRefs} from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  subTitle: {
    type: String,
    default: "",
  }
})
const {proxy} = getCurrentInstance();
const {title, subTitle} = toRefs(props)
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "",
});
</script>

<style lang="scss" scoped>
.page-title {
  text-align: center;
  padding: 70px 0;
  //background-color: $shopMainColor;

  h1 {
    font-weight: 600;
    font-size: $shopTitleFs;
    color: $shopTitleColor;
    line-height: 1;
  }

  h2 {
    font-weight: 100;
    font-size: $shopSubTitleFs;
    color: $shopTitleColor;
    line-height: 1;
    margin-top: 20px;
  }
}
</style>
