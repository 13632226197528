<template>
  <div class="match-content container">
    <div class="match-title dp_f">
      <div class="title">{{ title }}</div>
      <div class="line"></div>
      <div class="more" v-if="more" @click="handleClickMore">{{ isChecked ? '-' : '+' }}</div>
    </div>
    <div :class="['match-article',isChecked?'active':'']">
      <div>
        <slot>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref, toRefs} from "vue";

const props = defineProps({
  more: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  checked: {
    type: Boolean,
    default: false,
  },
})
const {more, title, checked} = toRefs(props);
//初始化是否打开
const isChecked = ref(checked.value)

const emit = defineEmits(['update:checked'])
const {proxy} = getCurrentInstance();

const handleClickMore = () => {
  isChecked.value = !isChecked.value
  emit('update:checked', isChecked.value)
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "matchTitle"
});
</script>

<style lang="scss" scoped>
.match-content {
  padding:0 70px;

  .match-title {
    .title {
      font-size: 30px;
      color: $shopFsColor;
      margin-right: 20px;
      font-weight: bold;
    }

    .line {
      flex: auto;
      height: 1px;
      border-top: 1px dashed #797A80;
    }

    .more {
      margin-left: 20px;
      width: 30px;
      height: 30px;
      line-height: 24px;
      text-align: center;
      font-size: 24px;
      color: $shopFsColor;
      cursor: pointer;
      user-select: none;
      border-radius: 50%;
      border: 1px solid $shopFsColor2;
      box-sizing: border-box;
    }
  }

  .match-article {
    //高度为0 ，加入active时高度为auto ，实现动画效果
    display: grid;
    grid-template-rows: 0fr;
    transition: .3s;
    overflow: hidden;
    > div {
      min-height: 0;
    }

    &.active {
      grid-template-rows: 1fr;
       padding-top: 66px;
      overflow: visible;
    }
  }
}
</style>
